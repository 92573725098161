/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProps {
	description?: string;
	image?: string;
	lang?: string;
	meta?: ConcatArray<
		| { name: string; content: any; property?: undefined }
		| { property: string; content: any; name?: undefined }
	>;
	title?: string;
}

const SEO: React.FC<SEOProps> = ({ description, lang, meta, title, image }) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
						author
						image
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const metaImage =
		image || `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			defaultTitle={site.siteMetadata.title}
			title={title}
			titleTemplate={`${site.siteMetadata.title} | %s`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title || site.siteMetadata.title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:image`,
					content: metaImage,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title || site.siteMetadata.title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					property: `twitter:image`,
					content: metaImage,
				},
			].concat(meta || [])}
		/>
	);
};

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	title: ``,
	description: ``,
	image: ``,
};

export default SEO;
